<template>
  <div>
    <v-row>
      <v-col md="12">
        <vl-map
          :style="heightStyle"
          :load-tiles-while-animating="true"
          :load-tiles-while-interacting="false"
        >
          <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

          <vl-interaction-select :features.sync="selectedFeatures" />

          <vl-layer-tile :z-index="0">
            <vl-source-osm></vl-source-osm>
          </vl-layer-tile>
          <vl-feature> </vl-feature>
          <template
            v-if="
              computedStatisticPoints.geoPoints &&
                computedStatisticPoints.geoPoints.length
            "
          >
            <MapGeoMultiPoints
              v-if="
                computedStatisticPoints.geoPoints[0].geo &&
                  computedStatisticPoints.geoPoints[0].geo.length
              "
              :geoPoints="computedStatisticPoints.geoPoints[0]"
            />
            <MapGeoMultiPoints
              v-if="
                computedStatisticPoints.geoPoints[1].geo &&
                  computedStatisticPoints.geoPoints[1].geo.length
              "
              :geoPoints="computedStatisticPoints.geoPoints[1]"
            />
            <MapGeoMultiPoints
              v-if="
                computedStatisticPoints.geoPoints[2].geo &&
                  computedStatisticPoints.geoPoints[2].geo.length
              "
              :geoPoints="computedStatisticPoints.geoPoints[2]"
            />
            <MapGeoMultiPoints
              v-if="
                computedStatisticPoints.geoPoints[3].geo &&
                  computedStatisticPoints.geoPoints[3].geo.length
              "
              :geoPoints="computedStatisticPoints.geoPoints[3]"
            />
          </template>
          <template v-if="goetelAreas && goetelAreas.length">
            <template v-for="(item, index) in goetelAreas">
              <vl-feature :key="index">
                <vl-geom-multi-polygon
                  :coordinates="convertPolygonBack(item.st_asgeojson)"
                ></vl-geom-multi-polygon>
                <vl-style-box>
                  <vl-style-fill color="rgba(180,220,255,0.5)"></vl-style-fill>
                  <vl-style-stroke color="#209bd8" :width="3"></vl-style-stroke>
                  <vl-style-text
                    :text="projectId.toString()"
                    font="bold 1rem sans-serif"
                    color="#209bd8"
                  ></vl-style-text>
                </vl-style-box>
              </vl-feature>
            </template>
          </template>
          <template v-if="point">
            <vl-feature>
              <vl-geom-point :coordinates="point"></vl-geom-point>
              <vl-style-box>
                <vl-style-circle :radius="8">
                  <vl-style-fill color="#ff0000"></vl-style-fill>
                  <vl-style-stroke
                    color="#000000"
                    :width="2"
                  ></vl-style-stroke>
                </vl-style-circle>
              </vl-style-box>
            </vl-feature>
          </template>
          <template v-if="radius && !point && !projectId">
            <vl-feature>
              <vl-geom-point :coordinates="radius"></vl-geom-point>
              <vl-style-box>
                <vl-style-circle :radius="200">
                  <vl-style-fill color="rgba(255,255,255,0.3)"></vl-style-fill>
                  <vl-style-stroke
                    color="#000000"
                    :width="2"
                  ></vl-style-stroke>
                </vl-style-circle>
              </vl-style-box>
            </vl-feature>
          </template>
          <template v-if="radiusSmall && !point && !projectId">
            <vl-feature>
              <vl-geom-point :coordinates="radiusSmall"></vl-geom-point>
              <vl-style-box>
                <vl-style-circle :radius="100">
                  <vl-style-fill color="rgba(255,255,255,0.3)"></vl-style-fill>
                  <vl-style-stroke
                    color="#880000"
                    :width="4"
                  ></vl-style-stroke>
                </vl-style-circle>
              </vl-style-box>
            </vl-feature>
          </template>
        </vl-map>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular
        :size="120"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import Vue from 'vue';
import proj4 from 'proj4';
import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css'; // needs css-loader

import { fromLonLat } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import MapGeoMultiPoints from './MapGeoMultiPoints.vue';
Vue.use(VueLayers);

proj4.defs(
  'EPSG:25832',
  '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
register(proj4);
Vue.use(VueLayers, {
  dataProjection: 'EPSG:25832'
});

export default {
  name: 'DetailMap',
  components: { MapGeoMultiPoints },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    optionalData: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    projectId: {
      type: Number,
      default: 0
    },
    height: {
      type: String,
      default: '503px',
      required: false
    },
    point: {
      type: Array,
      default: undefined,
      required: false
    },
    radius: {
      type: Array,
      default: undefined,
      required: false
    },
    radiusSmall: {
      type: Array,
      default: undefined,
      required: false
    },
    zoom: {
      type: Number,
      default: 14,
      required: false
    }
  },
  data: () => ({
    goetelAreas: undefined,
    center: [1083877.0610837992, 6676315.798540437],
    // zoom: 14,
    selectedFeatures: [],
    features: [],
    loading: false,
  }),
  computed: {
    heightStyle() {
      return `height: ${this.height}`;
    },
    computedStatisticPoints() {
      const greyPoints = [];
      const bluePoints = [];
      const redPoints = [];
      const yellowPoints = [];
      const geoPoints = [];
      this.data.forEach((item) => {
        if (item.geo) {
          if (item.isftth === false || item.ftth === false) {
            greyPoints.push(this.formatPoint(item.geo));
          } else {
            bluePoints.push(this.formatPoint(item.geo));
          }
        }
      });
      this.optionalData.forEach((item) => {
        if (item.geo) {
          if (item.hasDifferentProject === false) {
            redPoints.push(this.formatPoint(item.geo));
          } else {
            yellowPoints.push(this.formatPoint(item.geo));
          }
        }
      });
      geoPoints.push(greyPoints);
      geoPoints.push(bluePoints);
      geoPoints.push(redPoints);
      geoPoints.push(yellowPoints);
      return {
        geoPoints: [
          { geo: greyPoints, color: ['#ffffffCC', '#888888'] },
          { geo: bluePoints, color: ['#209bd8', '#1F4677'] },
          { geo: yellowPoints, color: ['#ffff3d', '#c2c200'] },
          { geo: redPoints, color: ['#e40000', '#430000'] }
        ]
      };
    }
  },
  watch: {
    projectId() {
      if (this.projectId !== 0) {
        this.getGoetelAreas();
      }
    },
    data(newVal) {
      this.center = this.formatPoint(newVal[0].geo);
    },
    point(newVal) {
      this.center = newVal;
    },
    radius(newVal) {
      if (this.point) {
        this.center = this.point;
      } else {
        this.center = newVal;
      }
    },
    radiusSmall(newVal) {
      if (this.point) {
        this.center = this.point;
      } else {
        this.center = newVal;
      }
    }
  },
  mounted() {
    if (this.projectId !== 0) {
      this.getGoetelAreas();
    }
    if (this.point) {
      this.center = this.point;
    } else if (this.radius) {
      this.center = this.radius;
    } else if (this.radiusSmall) {
      this.center = this.radiusSmall;
    } else if (this.data && this.data[0] && this.data[0].geo) {
      this.center = this.formatPoint(this.data[0].geo);
    }
  },
  methods: {
    formatPoint(point) {
      const stringArray = point.split(' ');

      const a = stringArray[0].replace('POINT(', '');
      const b = stringArray[1].replace(')', '');
      const raw = [];
      raw.push(parseFloat(a));
      raw.push(parseFloat(b));

      return raw;
    },
    convertPolygonBack(poly) {
      return JSON.parse(poly).coordinates;
    },
    fromLonLat(coordinate) {
      return fromLonLat(coordinate);
    },
    getGoetelAreas() {
      HTTP.get(`past/geo/goetel-area-polygons?projekt_id=${this.projectId}`)
        .then((response) => {
          this.goetelAreas = response.data;
          this.center = JSON.parse(response.data[0].center).coordinates;
        })
        .catch((error) => console.log(error));
    }
  }
};
</script>
