<template>
  <vl-feature>
    <vl-geom-multi-point :coordinates="geoPoints.geo"></vl-geom-multi-point>
    <vl-style-box>
      <vl-style-circle :radius="5">
        <vl-style-fill :color="geoPoints.color[0]"></vl-style-fill>
        <vl-style-stroke
          :color="geoPoints.color[1]"
          :width="2"
        ></vl-style-stroke>
      </vl-style-circle>
    </vl-style-box>
  </vl-feature>
</template>
<script>
export default {
  name: 'MapGeoMultiPoints',
  props: {
    geoPoints: {
      type: Object,
      required: true
    }
  }
};
</script>
