var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DisplayContainer',{attrs:{"label":_vm.label}},[_c('div',{staticClass:"search-wrapper"},[_c('SearchCustomerSelection',{attrs:{"label":"","state":_vm.currentState.searchState},on:{"stateChanged":function($event){return _vm.updateSearchState($event)},"confirmed":function($event){return _vm.retrieveCustomerContracts()}}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.retrieveCustomerContracts()}}},'v-btn',attrs,false),on),[_vm._v(" Kunden Suche "),_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}])},[_vm._v(" Kunden Suche ")])],1),(_vm.currentState.customerContracts || !_vm.contractDataLoading)?_c('v-data-table',{staticClass:"elevation-1 search-list",attrs:{"headers":_vm.listHeaders,"items":_vm.customers,"items-per-page":10,"item-key":"id","selectable-key":"customerId","show-select":"","single-select":"","no-data-text":"Kein Kunde gefunden.","loading":_vm.contractDataLoading,"loading-text":"Loading... Please wait","server-items-length":_vm.totalDataTableCount,"options":_vm.options,"footer-props":{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }},on:{"update:options":function($event){_vm.options=$event},"item-selected":function($event){return _vm.selectedItemChanged($event)},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.customerId",fn:function(ref){
      var item = ref.item;
return [(item.system)?_c('v-chip',{staticClass:"chip-system",attrs:{"color":_vm.getColor(item.system),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.system)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.customerId)+" ")]}},{key:"item.contractId",fn:function(ref){
      var item = ref.item;
return [_c('ul',_vm._l((item.contractId),function(selection,idx){return _c('li',{key:idx},[_vm._v(" "+_vm._s(selection)+" ")])}),0)]}},{key:"item.invoiceAddress",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"col-address"},_vm._l((item.invoiceAddress),function(selection,idx){return _c('div',{key:idx,staticClass:"row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[(selection.icon)?_c('v-icon',{staticClass:"icon-light"},[_vm._v(" "+_vm._s(selection.icon)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(selection.type))])]),_c('div',{staticClass:"label-address"},[_vm._v(" "+_vm._s(selection.address)+" ")])],1)}),0)]}}],null,true),model:{value:(_vm.selectedItemValue),callback:function ($$v) {_vm.selectedItemValue=$$v},expression:"selectedItemValue"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }