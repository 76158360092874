<template>
  <div>
    <DisplayContainer :label="label">
      <div class="search-wrapper">
        <SearchCustomerSelection
          label=""
          :state="currentState.searchState"
          @stateChanged="updateSearchState($event)"
          @confirmed="retrieveCustomerContracts()"
        />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              depressed
              v-bind="attrs"
              v-on="on"
              @click="retrieveCustomerContracts()"
            >
              Kunden Suche
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
          </template>
          Kunden Suche
        </v-tooltip>
      </div>

      <v-data-table
        v-if="currentState.customerContracts || !contractDataLoading"
        v-model="selectedItemValue"
        :headers="listHeaders"
        :items="customers"
        :items-per-page="10"
        item-key="id"
        selectable-key="customerId"
        show-select
        single-select
        no-data-text="Kein Kunde gefunden."
        class="elevation-1 search-list"
        :loading="contractDataLoading"
        loading-text="Loading... Please wait"
        :server-items-length="totalDataTableCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        @item-selected="selectedItemChanged($event)"
        @click:row="rowClicked"
      >
        <template v-slot:[`item.customerId`]="{ item }">
          <v-chip
            v-if="item.system"
            :color="getColor(item.system)"
            dark
            small
            class="chip-system"
          >
            {{ item.system }}
          </v-chip>
          {{ item.customerId }}
        </template>

        <template v-slot:[`item.contractId`]="{ item }">
          <ul>
            <li v-for="(selection, idx) in item.contractId" :key="idx">
              {{ selection }}
            </li>
          </ul>
        </template>

        <template v-slot:[`item.invoiceAddress`]="{ item }" class="col-address">
          <div class="col-address">
            <div
              v-for="(selection, idx) in item.invoiceAddress"
              :key="idx"
              class="row"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-icon v-if="selection.icon" class="icon-light">
                      {{ selection.icon }}
                    </v-icon>
                  </div>
                </template>
                <span>{{ selection.type }}</span>
              </v-tooltip>
              <div class="label-address">
                {{ selection.address }}
              </div>
            </div>
          </div>
        </template>
      </v-data-table>
    </DisplayContainer>
  </div>
</template>

<script>
import SearchCustomerSelection from '@/components/SearchCustomerSelection.vue';
import Loader from '@/components/elements/Loader.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';
import { objectToQueryParamString } from '@/util/ObjectUtil.js';

export default {
  name: 'SearchCustomer',
  components: {
    SearchCustomerSelection,
    Loader,
    DisplayContainer,
    TimeUtility
  },
  props: {
    label: {
      type: String,
      required: true
    },
    state: {
      type: Object,
      required: true,
      default() {
        return {
          selectedContract: null,
          customerId: null,
          customerContracts: null,
          recallDate: null,
          searchState: {
            customerName: null,
            billId: null,
            customerId: null,
            contractId: undefined,
            firstName: null,
            lastName: null,
            contactAddress: {
              zipCode: null,
              city: null,
              street: null,
              houseNumber: null,
              houseNumberSupplement: null
            }
          }
        };
      }
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    customerData: null,
    contractDataLoading: false,
    customerIdValidationError: null,
    contractValidationError: null,
    listHeaders: [
      { text: 'Name/Firma', value: 'customerName' },
      { text: 'System/Kundennummer', value: 'customerId' },
      { text: 'Vertragsnummer', value: 'contractId' },
      {
        text: 'Adresse (Installationsadresse & Rechnungsadresse)',
        value: 'invoiceAddress'
      },
      { text: 'Geburtsdatum', value: 'birthDate' },
      { text: 'E-Mail Adresse', value: 'email' }
    ],
    options: {},
    totalDataTableCount: 0
  }),
  computed: {
    currentState() {
      return { ...this.state };
    },
    customers() {
      if (this.currentState) {
        if (this.currentState.customerContracts) {
          if (this.currentState.customerContracts.result) {
            return this.currentState.customerContracts.result //
              .map((item, index) => ({
                id: index,
                customerName: this.getCustomerName(
                  item.firstName,
                  item.lastName,
                  item.company
                ),
                system: item.system,
                contractId: item?.contracts?.map((m) => m.contractId) || [],
                customerId: item.customerId,
                invoiceAddress:
                  item?.invoiceAddress
                    ?.filter((f) => f.address.trim().length !== 1)
                    .map((addr, indx) => ({
                      type: this.getAddressType(addr.addressType),
                      icon: this.getAddressIcon(addr.addressType),
                      address: addr.address
                    })) || [],
                invoiceNumber: '-',
                birthDate: this.formatDate(item.birthDate),
                email: item.email
              }));
          }
        }
      }
      return undefined;
    },
    selectedItemValue: {
      get: function () {
        if (!this.currentState.selectedContract) return [];
        return [this.currentState.selectedContract];
      },
      set: function (newValue) {}
    }
  },
  watch: {
    options: {
      handler() {
        this.retrieveCustomerContracts();
      },
      deep: true
    }
  },
  mounted: function () {
    this.validateInputs();
  },

  methods: {
    getAddressType(item) {
      if (!item) return;
      if (item === 'INSTALLATION') return 'Installationadresse';
      else if (item === 'INVOICE') return 'Rechnungsadresse';
      else return item;
    },
    getAddressIcon(item) {
      if (!item) return;
      if (item === 'INVOICE') return 'mdi-home-account';
      else if (item === 'INSTALLATION') return 'mdi-home-analytics';
      else return 'mdi-home-alert';
    },
    getColor(crmSystem) {
      if (crmSystem === 'VarioCRM') {
        return 'green';
      } else if (crmSystem === 'ACOS') {
        return 'orange';
      } else if (crmSystem === 'Commence') {
        return 'blue';
      } else {
        return 'grey';
      }
      /*
      const r = () => Math.floor(256 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
      */
    },
    getCustomerName(firstName, lastName, company) {
      var name;
      if (firstName && lastName) {
        name = lastName + ', ' + firstName;
      }
      if (firstName && !lastName) {
        name = firstName;
      }
      if (lastName && !firstName) {
        name = lastName;
      }
      if (name && company) {
        name = name + ' / ' + company;
      } else if (!name && company) {
        name = company;
      }
      return name;
    },
    selectedItemChanged(event) {
      const { item, value } = event;
      if (value) {
        this.updateState('selectedContract', item);
        this.$emit('inputValidationChanged', []);
      } else {
        this.updateState('selectedContract', null);
        this.$emit('inputValidationChanged', [this.customerIdValidationError]);
      }
    },
    rowClicked(item, row) {
      var rowSelect = !row.isSelected;
      row.select(rowSelect);

      var selectEvent = {
        item: item,
        value: rowSelect
      };

      this.selectedItemChanged(selectEvent);
    },
    updateSearchState: function (varValue) {
      this.currentState.searchState = varValue;
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    updateState: function (varName, varValue) {
      this.currentState[varName] = varValue;
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    retrieveCustomerContracts() {
      let { page, itemsPerPage } = this.options;

      if (!page) page = 1;
      if (!itemsPerPage) itemsPerPage = 10;

      const queryString = objectToQueryParamString(
        this.currentState.searchState
      );

      this.updateState('selectedContract', null);
      this.updateState('customerContracts', {});
      if (queryString) {
        this.contractDataLoading = true;
        HTTP.get(
          `/customer?${queryString}&page=${page}&pageSize=${itemsPerPage}`
        )
          .then((res) => {
            this.updateState('customerContracts', res.data);
            this.totalDataTableCount = res.data.total;
          })
          .catch((e) => {})
          .finally(() => {
            this.contractDataLoading = false;
          });
      }
    },
    validateCustomerId() {
      let inputValid = true;
      if (!this.currentState.customerId) {
        inputValid = false;
        this.customerIdValidationError =
          'Es muss eine Kundennummer angegeben werden!';
      } else if (isNaN(this.currentState.customerId)) {
        inputValid = false;
        this.customerIdValidationError =
          'Die Kundennummer muss numerisch sein!';
      } else {
        this.customerIdValidationError = null;
      }
      return inputValid;
    },
    validateContract() {
      if (!this.currentState.selectedContract) {
        this.contractValidationError = 'Es muss ein Vertrag ausgewählt werden!';
        return false;
      } else {
        this.contractValidationError = null;
        return true;
      }
    },
    validateInputs() {
      this.validateContract();
      const validationErrors = [];
      if (this.customerIdValidationError) {
        validationErrors.push(this.customerIdValidationError);
      }
      if (this.contractValidationError) {
        validationErrors.push(this.contractValidationError);
      }
      this.$emit('inputValidationChanged', validationErrors);
    },
    getContractTypeString(contractType) {
      if (contractType === 'SERVICE_ORDER') {
        return 'Dienstauftrag';
      } else if (contractType === 'HOUSE_CONNECTION_ORDER') {
        return 'Hausanschlussauftrag';
      } else {
        return 'Vertragstyp unbekannt';
      }
    },
    formatDate(date) {
      return date ? TimeUtility.formatLocalDate(date) : '-';
    }
  }
};
</script>

<style scoped>
.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loader {
  margin: auto;
  top: calc(50% - var(--goe-spacing-2));
  position: initial;
}
.input-with-button {
  display: flex;
}
.input-with-button .v-btn {
  align-self: center;
  margin-left: var(--goe-spacing-1);
}

.search-wrapper .v-btn {
  float: right;
}

.search-list {
  margin-top: 10px;
}

.table-item-count-wrapper {
  width: 100%;
}

.table-item-count {
  float: right;
  margin-right: 88px;
}
.chip-system {
  font-size: 8pt;
}
.icon-light {
  color: var(--goe-fontColor-highlight);
}
.label-address {
  margin-top: 3px;
}
.col-address {
  padding: 20px;
}
</style>
