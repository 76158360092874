<template>
  <div class="kpi-display">
    <v-row v-if="mode === 'avatar'" dense>
      <template v-for="(item, index) in kpiArray">
        <v-col :key="index">
          <v-avatar size="108" color="tertiary">
            <center>
              <v-icon>
                {{ item.icon }}
              </v-icon>
              <h3>
                {{ item.amount }}
                <small>{{ item.label }}</small>
              </h3>
            </center>
          </v-avatar>
        </v-col>
      </template>
    </v-row>
    <v-row v-if="mode === 'alert'" dense>
      <template v-for="(item, index) in kpiArray">
        <v-col :key="index">
          <v-alert
            color="tertiary"
            class="kpi-alert"
            border="left"
            dark
            text
            prominent
            :icon="item.icon"
          >
            <h3>
              {{ item.amount }}
              <small>{{ item.label }}</small>
            </h3>
          </v-alert>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'KpiDisplay',
  components: {},
  props: {
    kpi: {
      type: Array,
      required: false,
      default: undefined
    },
    projectLength: {
      type: Number,
      required: false,
      default: undefined
    },
    mode: {
      type: String,
      required: false,
      default: 'avatar'
    }
  },
  data: () => ({
    geoGoetelAddresses: undefined,
  }),
  computed: {
    kpiArray() {
      const Array = [
        {
          icon: 'mdi-map-marker-multiple',
          amount: this.kpi[0].totalAddresses,
          label: 'Adressen',
        },
        {
          icon: 'mdi-home-group',
          amount: this.kpi[0].totalHomes,
          label: 'Wohneinheiten',
        },
        {
          icon: 'mdi-office-building',
          amount: this.kpi[0].totalOffices,
          label: 'Gewerbeeinheiten',
        }
      ];
      if (this.projectLength) {
        Array.push({
          icon: 'mdi-bulldozer',
          amount: this.projectLength,
          label: 'Bauprojekte'
        });
      }
      return Array;
    }
  }
};
</script>
<style scoped lang="scss">
.kpi-display {
    color: #111111;
    h3 {
        font-family: monospace;
        font-size: 24px;
        color: #111111;
        display: block;
        width: 100%;
        small {
            font-size: 9px;
            font-family: Roboto;
            display: block;
            width: 100%;
        }
    }
    i {
        color: #209bd8;
        font-size: 28px;
    }
}
.kpi-alert {
  h3 {
    color: #209bd8;
    small {
      font-size: 10px;
    }
  }
  ::v-deep i {
    color: #209bd8 !important;
  }
}
</style>
