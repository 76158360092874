<template>
  <div>
    <v-row>
      <v-col md="12">
        <vl-map style="height: 400px">
          <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

          <vl-layer-vector :z-index="1">
            <vl-source-vector
              :features.sync="features"
              ident="the-source"
            ></vl-source-vector>

            <vl-style-box>
              <vl-style-stroke color="green"></vl-style-stroke>
              <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
            </vl-style-box>
          </vl-layer-vector>

          <vl-interaction-draw
            type="Polygon"
            source="the-source"
            @stop-click="alert(this.point)"
          >
            <vl-style-box>
              <vl-style-stroke color="blue"></vl-style-stroke>
              <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
            </vl-style-box>
          </vl-interaction-draw>

          <vl-interaction-select :features.sync="selectedFeatures" />

          <vl-layer-tile :z-index="0">
            <vl-source-osm></vl-source-osm>
          </vl-layer-tile>
        </vl-map>
        <v-btn
          v-if="features && features.length"
          class="my-2"
          @click="clearFeatures"
        >
          <v-icon>mdi-delete-empty</v-icon> alle Polygone löschen
        </v-btn>
        <v-alert
          class="infobox"
          dense
          text
          type="info"
        >
          <b>Bedienungshinweis:</b>
          Polygone werden im gegengesetzten Uhrzeigersinn gezeichnet. Ein
          weiterer Klick auf den letzten Punkt schließt das Ploygon ab.
        </v-alert>
      </v-col>
      <v-col md="12">
        <template v-if="features && features.length">
          <template v-for="(item, index) in features">
            <v-card :key="index" class="polygon-item card mb-2">
              <v-card-title>
                <v-icon>mdi-texture-box</v-icon> Projektgebiet
                {{ index + 1 }}
              </v-card-title>
              <v-card-text>
                <ul>
                  <template
                    v-for="(point, index2) in item.geometry.coordinates[0]"
                  >
                    <li :key="index2" style="list-style: none">
                      <v-icon>mdi-map-marker</v-icon> {{ point }}
                    </li>
                  </template>
                </ul>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :key="index"
                  small
                  class="float-right"
                  @click="getPolygonAddresses(item.geometry.coordinates[0])"
                >
                  Adressen abfragen
                </v-btn>
                <v-btn
                  :key="index"
                  small
                  class="float-right"
                  @click="deletePolygon(item.id)"
                >
                  Polygon löschen <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" width="60vw">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Prozess: {{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="showDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <br />
          <v-row>
            <v-col md="6">
              <h3>geo_goetel</h3>
              <template v-for="(item, index) in geoGoetelAddresses">
                <v-card :key="index">
                  <v-card-text>
                    {{ item.ax_strasse }} {{ item.ax_hausnum }}
                    {{ item.ax_hsnzus }}, {{ item.ax_plz }}
                    {{ item.ax_ort }} ({{ item.ax_ortst }})
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
            <v-col md="6">
              <h3>Open Street Map</h3>
              <template v-for="(item, index) in osmAddresses">
                <v-card :key="index">
                  <v-card-text>
                    {{ item.street }} {{ item.houseno }} , {{ item.zip }}
                    {{ item.city }} ({{ item.district }})
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular
        :size="120"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import proj4 from 'proj4';
import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css'; // needs css-loader

import { fromLonLat } from 'ol/proj';
import { register } from 'ol/proj/proj4';
Vue.use(VueLayers);

proj4.defs(
  'EPSG:25832',
  '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
register(proj4);

Vue.use(VueLayers, {
  dataProjection: 'EPSG:25832'
});

export default {
  name: 'ProjectMap',
  components: {},
  data: () => ({
    geoGoetelAddresses: undefined,
    osmAddresses: undefined,
    projection: 'EPSG:25832',
    center: [1083877.0610837992, 6676315.798540437],
    zoom: 9,
    rotation: 0,
    geolocPosition: undefined,
    clickCoordinate: undefined,
    selectedFeatures: [],
    deviceCoordinate: undefined,
    features: [],
    mapPanel: {
      tab: 'state'
    },
    panelOpen: true,
    mapVisible: true,
    drawControls: [
      {
        type: 'point',
        label: 'Draw Point',
        icon: 'map-marker'
      },
      {
        type: 'line-string',
        label: 'Draw LineString',
        icon: 'minus'
      },
      {
        type: 'polygon',
        label: 'Draw Polygon',
        icon: 'square-o'
      },
      {
        type: 'circle',
        label: 'Draw Circle',
        icon: 'circle-thin'
      },
      {
        type: undefined,
        label: 'Stop drawing',
        icon: 'times'
      }
    ],
    drawType: undefined,
    drawnFeatures: [],
    showDialog: false,
    loading: false
  }),
  computed: {},
  watch: {},
  async mounted() {
    // await this.initiateMap();
  },
  // mounted() {
  //   // this.getAddresses();
  // },
  methods: {
    convertPolygon(poly) {
      const resultArray = [];
      poly.forEach((item) => {
        resultArray.push(
          proj4('EPSG:3857', 'EPSG:25832', item)
            .toString()
            .replace('[', '')
            .replace(',', ' ')
            .replace(']', '')
        );
      });
      return resultArray
        .toString()
        .replace('[', '')
        .replace(']', '')
        .replace(/,/g, ', ');
      // return proj4('EPSG:4326', 'EPSG:25832', poly);
    },
    fromLonLat(coordinate) {
      return fromLonLat(coordinate);
    },
    clearFeatures() {
      this.selectedFeatures = [];
      this.features = [];
    },
    deletePolygon(featureID) {
      this.features.splice(
        this.features.findIndex((item) => item.id === featureID),
        1
      );
    },
    getPolygonAddresses(poly) {
      const convertedPolygon = this.convertPolygon(poly);
      this.loading = true;
      let loadingIndex = 0;
      axios
        .get(
          'https://localhost:8800/project-addresses?poly=' + convertedPolygon,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          }
        )
        .then(
          (response) => (this.geoGoetelAddresses = response.data),
          (this.showDialog = true),
          (loadingIndex = loadingIndex + 1)
        )
        .catch(
          (error) => console.log(error),
          (loadingIndex = loadingIndex + 1)
        );

      axios
        .get('https://localhost:8800/osm-addresses?poly=' + convertedPolygon, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
          }
        })
        .then(
          (response) => (this.osmAddresses = response.data),
          (this.showDialog = true),
          (loadingIndex = loadingIndex + 1)
        )
        .catch(
          (error) => console.log(error),
          (loadingIndex = loadingIndex + 1)
        );

      if (loadingIndex >= 2) {
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.infobox {
  margin-top: 15px;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.1);
}
</style>
