var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('vl-map',{style:(_vm.heightStyle),attrs:{"load-tiles-while-animating":true,"load-tiles-while-interacting":false}},[_c('vl-view',{attrs:{"zoom":_vm.zoom,"center":_vm.center},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){_vm.center=$event}}}),_c('vl-interaction-select',{attrs:{"features":_vm.selectedFeatures},on:{"update:features":function($event){_vm.selectedFeatures=$event}}}),_c('vl-layer-tile',{attrs:{"z-index":0}},[_c('vl-source-osm')],1),_c('vl-feature'),(
            _vm.computedStatisticPoints.geoPoints &&
              _vm.computedStatisticPoints.geoPoints.length
          )?[(
              _vm.computedStatisticPoints.geoPoints[0].geo &&
                _vm.computedStatisticPoints.geoPoints[0].geo.length
            )?_c('MapGeoMultiPoints',{attrs:{"geoPoints":_vm.computedStatisticPoints.geoPoints[0]}}):_vm._e(),(
              _vm.computedStatisticPoints.geoPoints[1].geo &&
                _vm.computedStatisticPoints.geoPoints[1].geo.length
            )?_c('MapGeoMultiPoints',{attrs:{"geoPoints":_vm.computedStatisticPoints.geoPoints[1]}}):_vm._e(),(
              _vm.computedStatisticPoints.geoPoints[2].geo &&
                _vm.computedStatisticPoints.geoPoints[2].geo.length
            )?_c('MapGeoMultiPoints',{attrs:{"geoPoints":_vm.computedStatisticPoints.geoPoints[2]}}):_vm._e(),(
              _vm.computedStatisticPoints.geoPoints[3].geo &&
                _vm.computedStatisticPoints.geoPoints[3].geo.length
            )?_c('MapGeoMultiPoints',{attrs:{"geoPoints":_vm.computedStatisticPoints.geoPoints[3]}}):_vm._e()]:_vm._e(),(_vm.goetelAreas && _vm.goetelAreas.length)?[_vm._l((_vm.goetelAreas),function(item,index){return [_c('vl-feature',{key:index},[_c('vl-geom-multi-polygon',{attrs:{"coordinates":_vm.convertPolygonBack(item.st_asgeojson)}}),_c('vl-style-box',[_c('vl-style-fill',{attrs:{"color":"rgba(180,220,255,0.5)"}}),_c('vl-style-stroke',{attrs:{"color":"#209bd8","width":3}}),_c('vl-style-text',{attrs:{"text":_vm.projectId.toString(),"font":"bold 1rem sans-serif","color":"#209bd8"}})],1)],1)]})]:_vm._e(),(_vm.point)?[_c('vl-feature',[_c('vl-geom-point',{attrs:{"coordinates":_vm.point}}),_c('vl-style-box',[_c('vl-style-circle',{attrs:{"radius":8}},[_c('vl-style-fill',{attrs:{"color":"#ff0000"}}),_c('vl-style-stroke',{attrs:{"color":"#000000","width":2}})],1)],1)],1)]:_vm._e(),(_vm.radius && !_vm.point && !_vm.projectId)?[_c('vl-feature',[_c('vl-geom-point',{attrs:{"coordinates":_vm.radius}}),_c('vl-style-box',[_c('vl-style-circle',{attrs:{"radius":200}},[_c('vl-style-fill',{attrs:{"color":"rgba(255,255,255,0.3)"}}),_c('vl-style-stroke',{attrs:{"color":"#000000","width":2}})],1)],1)],1)]:_vm._e(),(_vm.radiusSmall && !_vm.point && !_vm.projectId)?[_c('vl-feature',[_c('vl-geom-point',{attrs:{"coordinates":_vm.radiusSmall}}),_c('vl-style-box',[_c('vl-style-circle',{attrs:{"radius":100}},[_c('vl-style-fill',{attrs:{"color":"rgba(255,255,255,0.3)"}}),_c('vl-style-stroke',{attrs:{"color":"#880000","width":4}})],1)],1)],1)]:_vm._e()],2)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"size":120,"color":"primary","indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }