<template>
  <v-dialog
    v-model="showDialog"
    :persistent="newCommentContent !== ''"
    class="manually-order-dialog custom-scrollbar"
    max-width="950px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        color="secondary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="commentCount == 0">
          mdi-comment-plus
        </v-icon>
        <v-icon v-else-if="commentCount == 1">
          mdi-comment-text
        </v-icon>
        <v-icon v-else>
          mdi-comment-text-multiple
        </v-icon>
      </v-btn>
      <div>
        <span
          v-if="commentCount == 0"
          class="button-desc"
        >Kommentar schreiben</span>
        <span
          v-else-if="commentCount == 1"
          class="button-desc"
        >Ein Kommentar</span>
        <span
          v-else
          class="button-desc"
        >{{ commentCount }} Kommentare</span>
      </div>
    </template>
    <v-card :loading="commentDialogLoading || submittingComment">
      <v-card-title>
        <v-icon v-if="commentCount == 0">
          mdi-comment-plus
        </v-icon>
        <v-icon v-else-if="commentCount == 1">
          mdi-comment-text
        </v-icon>
        <v-icon v-else>
          mdi-comment-text-multiple
        </v-icon>
        <span class="ml-2">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <span v-if="commentDialogLoading">Daten werden geladen...</span>
        <template v-else>
          <template v-for="(comment, index) in comments">
            <div :key="index">
              <v-card
                class="mx-auto my-4"
                elevation="2"
                outlined
                shaped
              >
                <v-card-title>
                </v-card-title>

                <v-card-text class="mx-6">
                  <div v-html="comment.content"></div>
                </v-card-text>

                <v-card-actions>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon>mdi-account-edit</v-icon>
                        {{ comment.author }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-row
                      align="center"
                      justify="end"
                    >
                      <v-icon class="mr-1">
                        mdi-clock-outline
                      </v-icon>
                      <span class="subheading">{{ date(comment.createdAt) }}</span>
                    </v-row>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </div>
          </template>
        </template>
        <tiptap-vuetify
          v-model="newCommentContent"
          :disabled="commentDialogLoading || submittingComment"
          :extensions="editorExtensions"
          :card-props="{ flat: true }"
          :toolbar-attributes="{ color: 'var(--goe-fontColor-highlight) !important' }"
          placeholder="Kommentar schreiben..."
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          color="warning"
          @click="showDialog = false"
        >
          Schließen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="newCommentContent !== ''"
          :loading="submittingComment"
          color="primary"
          text
          @click="submitComment"
        >
          Kommentieren
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify';
import {
  addComment,
  getComments,
  getCommentCount,
  OBJECT_TYPE
} from '@/main/Comment.js';
import TimeUtility from '@/util/TimeUtility';

export default {
  name: 'CommentContainer',
  components: { TiptapVuetify },
  props: {
    objectType: {
      type: Number,
      required: true
    },
    objectId: {
      type: String || Number,
      required: true
    },
    objectInfo: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showDialog: false,
    commentDialogLoading: false,
    submittingComment: false,
    commentCount: 0,
    comments: [],
    editorExtensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    newCommentContent: ''
  }),
  computed: {
    dialogTitle() {
      let commentAmount;
      if (this.commentCount === 0) commentAmount = 'Kein';
      else if (this.commentCount === 1) commentAmount = 'Ein';
      else commentAmount = this.commentCount;

      const commentText = this.commentCount === 1 ? 'Kommentar' : 'Kommentare';

      switch (this.objectType) {
        case OBJECT_TYPE.CUSTOMER: {
          return `${commentAmount} Kunden${commentText.toLowerCase()}`;
        }
        case OBJECT_TYPE.CONTRACT: {
          return `${commentAmount} Kunden${commentText.toLowerCase()}`;
        }
        case OBJECT_TYPE.USER_TASK: {
          return `${commentAmount} ${commentText} zu User Task ${this.objectInfo}`;
        }
        default:
          return this.commentCount + ' ';
      }
    }
  },
  watch: {
    showDialog(newVal, oldVal) {
      if (!newVal) return;
      this.commentDialogLoading = true;
      this.fetchComments();
    }
  },
  created() {
    getCommentCount(this.objectType, this.objectId).then((count) => {
      this.commentCount = count;
      //   this.showDialog = true;
    });
  },
  methods: {
    async fetchComments() {
      this.comments = await getComments(this.objectType, this.objectId);
      this.commentCount = this.comments.length;
      this.commentDialogLoading = false;
    },
    async submitComment() {
      this.submittingComment = true;
      const newComment = await addComment(
        this.objectType,
        this.objectId,
        this.newCommentContent
      );
      this.newCommentContent = '';
      this.comments.unshift(newComment);
      this.commentCount = this.comments.length;
      this.submittingComment = false;
    },
    date(date) {
      return TimeUtility.intlFormatDateTime(date);
    }
  }
};
</script>

<style scoped>
.button-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.05em;
}

.button-with-text > .v-btn {
  width: max-content;
}

.button-with-text span.button-desc {
  font-size: 12px;
  white-space: nowrap;
}

div.task-title-content div.button-with-text .v-btn {
  min-height: 35px;
  min-width: 75px;
}

@media (max-width: 1400px) {
  .task-title-content {
    font-size: 16px;
  }
  .task-title-h2 {
    font-size: 18px;
  }
}
</style>
