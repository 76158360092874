<template>
  <div>
    <div class="d-flex">
      <h2>Projektübersicht</h2>
      <v-spacer> </v-spacer>
      <v-btn rounded class="mr-2" @click="getProjects">
        <v-icon>mdi-sync</v-icon> aktualisieren
      </v-btn>
      <v-badge
        v-if="incompleteProjects.length"
        bordered
        color="error"
        :content="incompleteProjects.length"
        overlap
        style="margin-left: -15px"
      >
        <v-btn
          v-if="incompleteProjects.length"
          rounded
          class="ml-4"
          @click="showIncompleteDialog = true"
        >
          unvollständige Projekte
        </v-btn>
      </v-badge>
      <v-spacer></v-spacer>
      <v-btn rounded class="mr-2" color="primary" @click="getProjectKpis">
        <v-icon>mdi-chart-box-plus-outline</v-icon> Kennzahlen laden
      </v-btn>
      <v-btn
        rounded
        class="mr-2"
        color="primary"
        @click="showAddressSearch = true"
      >
        <v-icon>mdi-home-search-outline</v-icon> Adresse suchen
      </v-btn>
      <v-text-field
        v-model="searchText"
        placeholder="Projekt suchen"
        prepend-inner-icon="mdi-map-search-outline"
        style="width: 100px"
        outlined
        rounded
        dense
        clearable
      >
      </v-text-field>
    </div>
    <v-btn text x-small @click="showFilter = showFilter ? false : true">
      Filter
      <template v-if="showFilter">
        <v-icon>mdi-menu-up</v-icon>
      </template>
      <template v-else>
        <v-icon>mdi-menu-down</v-icon>
      </template>
    </v-btn>
    <v-card v-if="showFilter">
      <v-card-text>
        <v-row>
          <v-col md="2">
            <h4 v-if="filteredProjects.length === aggregatedProjects.length">
              keine Filter aktiv | {{ filteredProjects.length }} Projekte
            </h4>
            <h4 v-else style="color: #209bd8">
              {{ filteredProjects.length }} gefilterte Projekte
            </h4>
          </v-col>
          <v-col md="10">
            <v-row dense align-content="end">
              <v-col md="2" v-if="!selectedCounty">
                &nbsp;
              </v-col>
              <v-col md="2">
                <v-autocomplete
                  v-model="selectedCounty"
                  :items="aggregatedProjects"
                  item-text="county"
                  item-value="county"
                  label="Landkreis"
                  rounded
                  outlined
                  dense
                  height="auto"
                  clearable
                >
                </v-autocomplete>
              </v-col>
              <v-col md="2" v-if="selectedCounty">
                <v-autocomplete
                  v-model="selectedCity"
                  :items="filteredProjects"
                  item-text="city"
                  item-value="city"
                  label="Ort"
                  rounded
                  outlined
                  dense
                  height="auto"
                  clearable
                >
                </v-autocomplete>
              </v-col>
              <v-col md="3">
                <v-select
                  v-model="selectedDistributionStatus"
                  :items="distributionStatuses"
                  item-text="description"
                  item-value="name"
                  label="Vertriebsphase"
                  rounded
                  outlined
                  dense
                  height="auto"
                  small
                  clearable
                  multiple
                  chips
                >
                  <template v-slot:selection="{ item }">
                    <v-chip :color="item.color" small label dark>
                      <span>{{ item.description }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3">
                <v-select
                  v-model="selectedConstructionStatus"
                  :items="constructionStatuses"
                  item-text="description"
                  item-value="name"
                  label="Baustatus"
                  rounded
                  outlined
                  dense
                  small
                  clearable
                  multiple
                  chips
                >
                  <template v-slot:selection="{ item }">
                    <v-chip :color="item.color" small label dark>
                      <span>{{ item.description }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col md="2">
                <v-select
                  v-model="selectedTariffModel"
                  :items="tariffModels"
                  item-text="name"
                  item-value="name"
                  label="Tarifmodell"
                  rounded
                  outlined
                  dense
                  height="30"
                  clearable
                  multiple
                  chips
                >
                  <template v-slot:selection="{ item }">
                    <v-chip small>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <br />
    <v-data-table
      :headers="tableHeaders"
      :items="filteredProjects"
      :itemsPerPage="50"
      multi-sort
      class="elevation-1 project-table"
      :loading="loading"
      @click:row="showDetails"
    >
      <template v-slot:[`item.projektId`]="{ item }">
        <v-chip color="#209bd8" dark>
          <b v-if="item && item.projektId">{{ item.projektId }}</b>
        </v-chip>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-chip color="#eee" dark label text-color="#000">
          <b v-if="item && item.name">{{ item.name }}</b>
        </v-chip>
      </template>
      <template v-slot:[`item.projectStatus`]="{ item }">
        <v-chip
          v-if="item.projectStatus && item.projectStatus.description"
          :color="item.projectStatus.color"
          small
          outlined
        >
          <span style="font-family: consolas">{{
            item.projectStatus.name
          }}</span>
        </v-chip>
        <span v-else> - </span>
      </template>
      <template v-slot:[`item.distributionStatus`]="{ item }">
        <v-chip
          v-if="item.distributionStatus && item.distributionStatus.description"
          :color="item.distributionStatus.color"
          dark
          label
        >
          {{ item.distributionStatus.description }}
        </v-chip>
        <span v-else> - </span>
      </template>
      <template v-slot:[`item.constructionStatus`]="{ item }">
        <v-chip
          v-if="item.constructionStatus && item.constructionStatus.description"
          :color="item.constructionStatus.color"
          dark
          label
          outlined
        >
          <v-icon>mdi-bulldozer</v-icon>
          {{ item.constructionStatus.description }}
        </v-chip>
        <span v-else> - </span>
      </template>
      <template v-slot:[`item.vPhase`]="{ item }">
        <v-chip
          v-if="item.vPhase && item.vPhase !== '-'"
          :color="getPhaseColor(item.vPhase)"
          dark
          label
          outlined
        >
          {{ item.vPhase }}
        </v-chip>
      </template>
      <template v-slot:[`item.vPlan`]="{ item }">
        <v-chip
          v-if="item.vPlan && item.vPlan !== '-'"
          :color="getPlanColor(item.vPlan)"
          dark
          label
          outlined
        >
          {{ item.vPlan }}
        </v-chip>
      </template>
    </v-data-table>
    <br /><br />
    <v-dialog
      v-model="detailsVisible"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card v-if="selectedProject">
        <v-toolbar color="primary" dark>
          Details zu Projekt {{ selectedProject.projektId }} -
          {{ selectedProject.name }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="detailsVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text style="max-height: calc(90vh - 64px); overflow-y: scroll; overflow-x: hidden;">
          <ProjectDetails :project="selectedProject" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddressSearch"
      transition="dialog-zop-transition"
      max-width="600"
      top="10vh"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          Addresse suchen
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="showAddressSearch = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-10">
          <AddressAutocomplete @update="updateAddress" />
          <div class="d-flex mt-10">
            <v-text-field
              v-model="errorTolerance"
              label="Fehlertoleranz in Meter"
              persistent-placeholder
              dense
            />
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="getProperty">
              Adresse suchen
            </v-btn>
          </div>
          <v-card v-if="propertyResult">
            <v-card-title> Ergebnis </v-card-title>
            <v-card-text>
              <template
                v-if="propertyResult.addressIsValid && propertyResult.ags27"
              >
                <v-icon color="success"> mdi-check </v-icon> Adresse ist valide
                - AGS27: {{ propertyResult.ags27 }} <br />
              </template>
              <template v-else>
                <v-icon color="error"> mdi-close </v-icon> Adresse ist nicht
                valide <br />
              </template>
              <template v-if="propertyResult.projectId">
                <v-icon color="success"> mdi-check </v-icon> Projekt-ID:
                {{ propertyResult.projectId }} <br />
              </template>
              <template v-else>
                <v-icon color="error"> mdi-close </v-icon> Addresse befindet
                sich nicht in einem Projektgebiet <br />
              </template>
              <template v-if="propertyResult.statusDescription">
                <v-icon color="success"> mdi-check </v-icon> Projekt-Status:
                {{ propertyResult.statusDescription }} <br />
              </template>
              <template
                v-if="
                  propertyResult.errorToleranceMeters &&
                  propertyResult.errorToleranceMeters > 0
                "
              >
                <v-icon color="warning"> mdi-alert-circle </v-icon>
                Fehlertoleranz {{ propertyResult.errorToleranceMeters }} Meter
                <br />
              </template>
              <div v-if="propertyResult.projectId" class="d-flex mt-4">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="
                    (showAddressSearch = false),
                      (searchText = propertyResult.projectId)
                  "
                >
                  Ergebnis in Projektübersicht übernehmen
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showIncompleteDialog"
      transition="dialog-bottom-transition"
      max-width="1200"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          Projekte ohne Polygon
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="showIncompleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="incompleteProjectHeaders"
            :items="incompleteProjects"
            :itemsPerPage="100"
            multi-sort
            dense
            class="elevation-1 mt-2"
          >
            <template v-slot:[`item.projektId`]="{ item }">
              <v-chip color="red" dark small>
                <b v-if="item && item.projektId">{{ item.projektId }}</b>
              </v-chip>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <v-chip color="#eee" dark small label text-color="#000">
                <b v-if="item && item.name">{{ item.name }}</b>
              </v-chip>
            </template>
            <template v-slot:[`item.distributionStatus`]="{ item }">
              <v-chip
                v-if="item.distributionStatus && item.color"
                :color="item.color"
                dark
                label
                small
              >
                {{ item.distributionStatus }}
              </v-chip>
              <span v-else> - </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DetailMap from './DetailMap.vue';
import ProjectDetails from './ProjectDetails.vue';
import mondayApi, { convertMondayItems } from '@/main/mondayApi.js';
import { getAllItemsWithProjektId } from '@/graphql/mondayQuerys.gql';
import NotificationObject from '@/main/NotificationObject.js';
import AddressAutocomplete from '@/components/orderEntry/orderCreation/customerDataSelection/AddressAutocomplete.vue';
import { HTTP } from '../../main/httpClient';
// import { filter } from 'vue/types/umd';

export default {
  name: 'ProjectList',
  components: {
    DetailMap,
    ProjectDetails,
    AddressAutocomplete
  },
  data: () => ({
    projects: [],
    aggregatedProjects: [],
    projectStrings: [],
    projectKpis: [],
    loading: undefined,
    detailsVisible: false,
    selectedProject: undefined,
    selectedProjectId: undefined,
    selectedProjectName: undefined,
    distributionStatuses: [],
    constructionStatuses: [],
    selectedDistributionStatus: undefined,
    selectedConstructionStatus: undefined,
    selectedCounty: undefined,
    selectedCity: undefined,
    tariffModels: [],
    selectedTariffModel: undefined,
    searchText: undefined,
    mondayProjects: undefined,
    mondayItems: undefined,
    projectsReady: false,
    mondayReady: false,
    rawProjects: [],
    incompleteProjects: [],
    showIncompleteDialog: false,
    address: undefined,
    propertyResult: undefined,
    showAddressSearch: false,
    showFilter: true,
    errorTolerance: 0,
    // filtererdProjects: [],
    headers: [
      { text: 'Projekt-ID', value: 'projektId', class: 'project-id' },
      { text: 'Projektname', value: 'name' },
      { text: 'Ort', value: 'city' },
      { text: 'Ortsteil', value: 'district' },
      { text: 'Landkreis', value: 'county' },
      { text: 'Status', value: 'projectStatus' },
      { text: 'Vertriebsphase', value: 'distributionStatus' },
      { text: 'Baustatus', value: 'constructionStatus' },
      { text: 'Kostenträger-Nr', value: 'ktr' },
      // { text: 'Vertriebsphase', value: 'vPhase' },
      // { text: 'Vorplanung', value: 'vPlan' },
      { text: 'Tarifmodell', value: 'tariffModel.name' },
      // { text: 'Vertriebsstatus', value: 'distributionStatus.description' },
      { text: 'Versandart', value: 'shippingMethod.description' }
    ],
    incompleteProjectHeaders: [
      { text: 'Projekt-ID', value: 'projektId', class: 'project-id' },
      { text: 'Projektname', value: 'name' },
      { text: 'Vertriebsphase', value: 'distributionStatus' }
    ]
  }),
  computed: {
    filteredProjects() {
      let filtered = [];
      let countyFiltered = [];
      let cityFiltered = [];
      let distributionStatusFiltered = [];
      let constructionStatusFiltered = [];
      let tariffModelFiltered = [];

      if (this.searchText && isNaN(+this.searchText)) {
        // filtered = this.aggregatedProjects.filter(item => item.name.toLowerCase().includes(this.searchText.toLowerCase()) || item.county?.toLowerCase().includes(this.searchText.toLowerCase()));
        filtered = this.aggregatedProjects.filter((item) =>
          item.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else if (this.searchText) {
        filtered = this.aggregatedProjects.filter(
          (item) => item.projektId === parseInt(this.searchText)
        );
      } else {
        filtered = this.aggregatedProjects;
      }

      if (this.selectedCounty) {
        countyFiltered = this.aggregatedProjects.filter(
          (item) => item.county === this.selectedCounty
        );
      } else {
        countyFiltered = filtered;
      }

      if (this.selectedCity) {
        cityFiltered = countyFiltered.filter(
          (item) => item.city === this.selectedCity
        );
      } else {
        cityFiltered = countyFiltered;
      }

      if (
        this.selectedDistributionStatus &&
        this.selectedDistributionStatus.length
      ) {
        distributionStatusFiltered = cityFiltered.filter((item) =>
          this.selectedDistributionStatus.includes(
            item.distributionStatus?.name
          )
        );
      } else {
        distributionStatusFiltered = cityFiltered;
      }

      if (
        this.selectedConstructionStatus &&
        this.selectedConstructionStatus.length
      ) {
        constructionStatusFiltered = distributionStatusFiltered.filter((item) =>
          this.selectedConstructionStatus.includes(
            item.constructionStatus?.name
          )
        );
      } else {
        constructionStatusFiltered = distributionStatusFiltered;
      }

      if (this.selectedTariffModel && this.selectedTariffModel.length) {
        tariffModelFiltered = constructionStatusFiltered.filter(
          (item) =>
            this.selectedTariffModel.includes(item.tariffModel?.name) ||
            this.selectedTariffModel.includes(item.businessTariffModel?.name)
        );
      } else {
        tariffModelFiltered = constructionStatusFiltered;
      }

      return tariffModelFiltered && tariffModelFiltered.length
        ? tariffModelFiltered
        : [];
    },
    tableHeaders() {
      let mergedArray;
      const baseArray = [
        { text: 'Projekt-ID', value: 'projektId', class: 'project-id' },
        { text: 'Projektname', value: 'name' },
        { text: 'Ort', value: 'city' },
        { text: 'Ortsteil', value: 'district' },
        { text: 'Landkreis', value: 'county' }
      ];
      const infoArray = [
        // { text: 'Status (intern)', value: 'projectStatus' },
        { text: 'Vertriebsphase', value: 'distributionStatus' },
        { text: 'Kostenträger-Nr', value: 'ktr' },
        // { text: 'Vertriebsphase', value: 'vPhase' },
        { text: 'Baustatus', value: 'constructionStatus' },
        // { text: 'Vorplanung', value: 'vPlan' },
        { text: 'Tarifmodell', value: 'tariffModel.name' },
        // { text: 'Vertriebsstatus', value: 'distributionStatus.description' },
        { text: 'Versandart', value: 'shippingMethod.description' }
      ];
      const kpiArray = [
        { text: 'BP+ (Plan)', value: 'buildingsPassedPlusPlan' },
        { text: 'HP (Plan)', value: 'homesPassedPlan' },
        { text: 'Aufträge (ist)', value: 'contractCount' },
        // { text: 'Homes Activated (Pay)', value: 'homesActivatedPay' },
        { text: 'BP+ Quote', value: 'buildingsPassedQuota' },
        { text: 'HP Quote', value: 'homesPassedQuota' }
      ];
      if (this.projectKpis && this.projectKpis.length) {
        mergedArray = [...baseArray, ...kpiArray, ...infoArray];
      } else {
        mergedArray = [...baseArray, ...infoArray];
      }
      return mergedArray;
    }
  },
  watch: {},
  beforeMount() {
    this.loading = true;
  },
  async mounted() {
    this.loading = true;
    this.getProjects();
    this.getIncompleteProjects();
    this.distributionStatuses = await this.getCollection(
      'distributionStatuses'
    );
    this.constructionStatuses = await this.getCollection(
      'constructionStatuses'
    );
    this.tariffModels = await this.getCollection('tariffModels');
    // this.fetchMondayData();
  },
  methods: {
    getCollection(collection) {
      this.loading = true;
      return HTTP.get('/past/' + collection)
        .then((response) => {
          this.loading = false;
          return response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getProjects() {
      console.log('getProjects');
      this.loading = true;
      HTTP.get('/past/projects')
        .then((response) => {
          this.projects = this.mapProjects(response.data);
          this.rawProjects = response.data;
          if (response.data && response.data.length) {
            this.loading = true;
            // this.fetchMondayData();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'warning',
              'Projeke nur im goetel VPN verfügbar'
            )
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getIncompleteProjects() {
      this.loading = true;
      HTTP.get('/past/geo/incomplete-projects')
        .then((response) => {
          this.incompleteProjects = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'warning',
              'Projeke nur im goetel VPN verfügbar'
            )
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProjectKpis() {
      this.loading = true;
      HTTP.get('/past/projectStatisticCaches')
        .then((response) => {
          if (response.data && response.data.length) {
            this.projectKpis = response.data;
            this.projects = this.mapKpis(this.rawProjects, response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProperty() {
      this.loading = true;
      const zipCode =
        this.address && this.address.zipCode ? this.address.zipCode : '';
      const city = this.address && this.address.city ? this.address.city : '';
      const street =
        this.address && this.address.street ? this.address.street : '';
      const houseNumber =
        this.address && this.address.houseNumber
          ? this.address.houseNumber
          : '';
      const houseNumberSupplement =
        this.address && this.address.houseNumberSupplement
          ? this.address.houseNumberSupplement
          : '';
      const errorToleranceMeters =
        this.errorTolerance && !isNaN(+this.errorTolerance)
          ? parseInt(this.errorTolerance)
          : 0;

      const queryString =
        '?zip=' +
        zipCode +
        '&city=' +
        city +
        '&street=' +
        street +
        '&houseno=' +
        houseNumber +
        '&errorToleranceMeters=' +
        errorToleranceMeters +
        '&housenoSuppl=' +
        houseNumberSupplement +
        '&similarity=100';

      HTTP.get('/past/geo/property-status' + queryString)
        .then((response) => {
          this.propertyResult = response.data ? response.data : undefined;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'warning',
              'Feherhafte Adresse oder kein Ergebnis'
            )
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateAddress(address) {
      this.address = address.value;
    },
    mapProjects(projects) {
      const returnArray = [];
      const stringArray = [];
      let obj;
      const _this = this;

      projects.forEach(function (item, index) {
        if (item && item.deleted !== true) {
          obj = {
            projektId: item.projektId,
            name: item.name,
            city: item.city,
            district: item.district,
            county: item.county,
            projectStatus: item.projectStatus,
            distributionStatus: item.distributionStatus,
            constructionStatus: item.constructionStatus,
            technologyStatus: item.technologyStatus,
            ktr: item.ktr,
            tariffModel: item.tariffModel,
            businessTariffModel: item.businessTariffModel,
            vPhase: '-',
            vPlan: '-',
            shippingMethod: item.shippingMethod,
            fttcInstantSupply: item.fttcInstantSupply
          };

          const nameString =
            item.projektId + ', ' + item.name + ', ' + item.county;
          const ktrString = item.ktr ? ', ' + item.ktr : '';
          const projectString = nameString + ktrString;

          const ktrObj = {
            projektId: item.projektId,
            longName: projectString
          };

          stringArray.push(ktrObj);

          returnArray.push(obj);
        }
        if (index === projects.length - 1) {
          _this.$store.commit(
            'addNotification',
            new NotificationObject('success', 'Projekte geladen')
          );
        }
      });
      this.projectStrings = stringArray;
      this.aggregatedProjects = returnArray;
    },
    mapMondayProjects(projects, mondayArray) {
      const returnArray = [];
      let obj;

      let filteredMondayObj;
      let vertriebsphasen = '-';
      let vorplanung = '-';

      projects.forEach(function (item) {
        if (item && item.deleted !== true) {
          if (mondayArray && mondayArray.length) {
            filteredMondayObj = mondayArray.filter(
              (monday) => parseInt(monday.projektID) === item.projektId
            );
            vertriebsphasen =
              filteredMondayObj &&
              filteredMondayObj[0] &&
              filteredMondayObj[0].vertriebsphasen
                ? filteredMondayObj[0].vertriebsphasen
                : '-';
            vorplanung =
              filteredMondayObj &&
              filteredMondayObj[0] &&
              filteredMondayObj[0].vorplanung
                ? filteredMondayObj[0].vorplanung
                : '-';
          }

          obj = {
            projektId: item.projektId,
            name: item.name,
            city: item.city,
            district: item.district,
            county: item.county,
            projectStatus: item.projectStatus,
            distributionStatus: item.distributionStatus,
            ktr: item.ktr,
            tariffModel: item.tariffModel,
            vPhase: vertriebsphasen,
            vPlan: vorplanung,
            shippingMethod: item.shippingMethod
          };
          returnArray.push(obj);
        }
      });
      this.aggregatedProjects = returnArray;
    },
    mapKpis(projects, projectKpis) {
      const returnArray = [];
      let obj;

      let filteredKpiObj;
      let buildingsPassedPlusPlan;
      let buildingsPassedQuota;
      let contractCount;
      let homesActivatedPay;
      let homesActivatedTech;
      let homesPassedPlan;
      let homesPassedQuota;

      function formatPercent(value) {
        if (value || value === 0) {
          return (value * 100).toFixed(2) + ' %';
        }
      }
      projects.forEach(function (item) {
        if (item && item.deleted !== true) {
          if (projectKpis && projectKpis.length) {
            filteredKpiObj = projectKpis.filter(
              (kpi) => parseInt(kpi.projektId) === parseInt(item.projektId)
            );
            buildingsPassedPlusPlan =
              filteredKpiObj && filteredKpiObj[0]
                ? filteredKpiObj[0].buildingsPassedPlusPlan
                : '-';
            buildingsPassedQuota =
              filteredKpiObj && filteredKpiObj[0]
                ? formatPercent(filteredKpiObj[0].buildingsPassedQuota)
                : '-';
            contractCount =
              filteredKpiObj && filteredKpiObj[0]
                ? filteredKpiObj[0].contractCount
                : '-';
            homesActivatedPay =
              filteredKpiObj && filteredKpiObj[0]
                ? filteredKpiObj[0].homesActivatedPay
                : '-';
            homesActivatedTech =
              filteredKpiObj && filteredKpiObj[0]
                ? filteredKpiObj[0].homesActivatedTech
                : '-';
            homesPassedPlan =
              filteredKpiObj && filteredKpiObj[0]
                ? filteredKpiObj[0].homesPassedPlan
                : '-';
            homesPassedQuota =
              filteredKpiObj && filteredKpiObj[0]
                ? formatPercent(filteredKpiObj[0].homesPassedQuota)
                : '-';
          }

          obj = {
            projektId: item.projektId,
            name: item.name,
            city: item.city,
            district: item.district,
            county: item.county,
            projectStatus: item.projectStatus,
            distributionStatus: item.distributionStatus,
            ktr: item.ktr,
            tariffModel: item.tariffModel,
            vPhase: '-',
            vPlan: '-',
            shippingMethod: item.shippingMethod,
            fttcInstantSupply: item.fttcInstantSupply,
            buildingsPassedPlusPlan: buildingsPassedPlusPlan,
            buildingsPassedQuota: buildingsPassedQuota,
            contractCount: contractCount,
            homesActivatedPay: homesActivatedPay,
            homesActivatedTech: homesActivatedTech,
            homesPassedPlan: homesPassedPlan,
            homesPassedQuota: homesPassedQuota
          };
          returnArray.push(obj);
        }
      });
      this.aggregatedProjects = returnArray;
    },
    showDetails(item) {
      this.selectedProject = item;
      this.detailsVisible = true;
    },
    async fetchMondayData() {
      this.loading = true;
      const { data } = await mondayApi(getAllItemsWithProjektId, {
        boardId: 1117087335, // prod board: 1117087335, dev board: 1126316462
        fields: ['zahlen4', 'status', 'status_10']
      });

      const items = await convertMondayItems(data.boards[0].items);

      const mondayArray = [];

      items.forEach((item, index) => {
        // console.log(index, item);
        if (item.projektID) {
          mondayArray.push(item);
        }
      });

      this.mondayItems = mondayArray;

      await this.mapMondayProjects(this.rawProjects, mondayArray);
      // this.mondayReady = true;
      this.$store.commit(
        'addNotification',
        new NotificationObject(
          'success',
          'Zusätzliche Projektinfos aus monday synchronisiert'
        )
      );
      this.loading = false;
    },
    getMondayProjects(projectIds) {
      if (Number.isInteger(projectIds)) projectIds = [projectIds];
      if (!Array.isArray(projectIds)) {
        throw new Error('ProjectIds has to be an array of integers');
      }

      const projects = [];

      for (const projectId of projectIds) {
        const project = this.mondayProjects.get(parseInt(projectId)) || {
          projectID: projectId
        };

        projects.push(project);
      }

      return projects;
    },
    getPlanColor(plan) {
      let color = '#C4C4C4';
      switch (plan) {
        case 'aktiv':
          color = '#A25DDC';
          break;
        case 'Q3/2022':
          color = '#E2445C';
          break;
        case 'Q4/2022':
          color = '#0086C0';
          break;
        case 'Q1/2023':
          color = '#00C875';
          break;
        case 'Q2/2023':
          color = '#579BFC';
          break;
        case 'Q3/2023':
          color = '#037F4C';
          break;
        case 'Q4/2023':
          color = '#FFCB00';
          break;
        default:
          color = '#C4C4C4';
          break;
      }
      return color;
    },
    getPhaseColor(phase) {
      let color = '#9D99B9';
      switch (phase) {
        case 'Neu':
          color = '#579BFC';
          break;
        case 'Kooperation':
          color = '#FF642E';
          break;
        case 'Pre-Sales':
          color = '#A25DDC';
          break;
        case 'Sales':
          color = '#FF5AC4';
          break;
        case 'Final Sales':
          color = '#037F4C';
          break;
        case 'Projekt Hold':
          color = '#888888';
          break;
        case 'Forecast':
          color = '#CAB641';
          break;
        case 'Übergabe an Bau':
          color = '#9CD326';
          break;
        case 'Projekt Abbruch':
          color = '#7F5347';
          break;
        default:
          color = '#9D99B9';
          break;
      }
      return color;
    }
  }
};
</script>
<style lang="scss" scoped>
.project-id {
  color: #ff0000;
}
h2 {
  font-size: 24px;
  color: #209bd8;
}
.row ::v-deep .v-text-field__details {
  display: none !important;
}
.project-table th {
  padding: 0 10px;
}
.v-text-field--outlined.v-select.v-select--chips ::v-deep input {
  min-height: auto;
}
</style>
